import { render, staticRenderFns } from "./bill.vue?vue&type=template&id=65128ef9&scoped=true"
import script from "./bill.vue?vue&type=script&lang=js"
export * from "./bill.vue?vue&type=script&lang=js"
import style0 from "./bill.vue?vue&type=style&index=0&id=65128ef9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65128ef9",
  null
  
)

component.options.__file = "bill.vue"
export default component.exports