<template>
  <div>
    <el-card class="box-card" style="margin-right: 7px;">
      <el-row>
        <el-form label-width="110px">
          <el-col :span="6">
            <el-form-item label="结算对象:">
              <el-input
                v-model="queryInfo.condition.accountUnite"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>
                       <el-col :span="6">
            <el-form-item label="核算名称:">
              <el-input
                v-model="queryInfo.condition.accountItemName"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="金融单号:">
              <el-input
                v-model="queryInfo.condition.financeCode"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="用户姓名:">
              <el-input
                v-model="queryInfo.condition.userName"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="业务类型:">
              <el-select
                v-model="queryInfo.condition.businessTypeCode"
                placeholder="请选择"
                style="width:100%"
                clearable
              >
                <el-option
                  v-for="item in businessTypeOptions"
                  :key="item.itemCode"
                  :label="item.itemName"
                  :value="item.itemCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态:">
              <el-select
                v-model="queryInfo.condition.status"
                placeholder="请选择"
                style="width:100%"
                clearable
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="expand">
            <el-form-item label="生成时间:">
              <el-date-picker
                v-model="createDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%;"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="expand">
            <el-form-item label="发起时间:">
              <el-date-picker
                v-model="initiateDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%;"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="expand">
            <el-form-item label="代理商确认时间:">
              <el-date-picker
                v-model="agentInsureDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%;"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="expand">
            <el-form-item label="支付申请时间:">
              <el-date-picker
                v-model="paymentApplyDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%;"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="expand">
            <el-form-item label="支付完成时间:">
              <el-date-picker
                v-model="paymentFinishDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%;"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8" v-if="expand">
            <el-form-item label="付款任务单据号:">
              <el-input
                v-model="queryInfo.condition.paymentTaskNum"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col> -->
          <el-col :span="6" style="padding-left: 50px;">
            <el-button type="primary" icon="el-icon-search" @click="toSearch"
              >查询</el-button
            >
            <el-button icon="el-icon-refresh" @click="toReset">重置</el-button>
            <el-button type="text" @click="toExpand" v-if="!expand">
              展开<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-button type="text" @click="toExpand" v-if="expand">
              收起<i class="el-icon-arrow-up el-icon--right"></i>
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card class="box-card" style="margin: 7px 7px 7px 0;">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%;margin-top: 0;"
        height="calc(100vh - 260px)"
        border
      >
        <el-table-column label="操作" fixed width="150" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="toShowLog(scope.row)"
              style="padding: 0;"
              >日志</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="financeCode"
          label="金融单号"
          width="200"
          align="center"
        >
        </el-table-column> -->
        <el-table-column
          prop="businessTypeName"
          label="业务类型"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="userName" label="用户姓名" align="center">
        </el-table-column>
        <el-table-column
          prop="accountUnite"
          label="结算对象"
          min-width="190px"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="moduleNum" label="组件片数" align="center">
        </el-table-column>
        <el-table-column
          prop="accountItemName"
          label="核算名称"
          min-width="140px"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="unitePrice"
          label="单价(元/板)"
          align="center"
          min-width="130px"
        >
        </el-table-column>
        <el-table-column
          prop="payPrice"
          label="本次付款金额(元)"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="totalPrice" label="总计(元)" align="center">
        </el-table-column>
        <!-- <el-table-column
          prop="paymentTaskNum"
          label="付款任务单据号"
          width="120"
          align="center"
        >
        </el-table-column> -->
        <el-table-column
          prop="createDate"
          label="生成时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="initiateDate"
          label="发起时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agentInsureDate"
          label="代理商确认时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="paymentApplyDate"
          label="支付申请时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="paymentFinishDate"
          label="支付完成时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" label="状态" width="120" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.status == '0'">未支付</el-tag>
            <el-tag type="success" v-if="scope.row.status == '1'"
              >已支付</el-tag
            >
            <el-tag type="warning" v-if="scope.row.status == '3'"
              >已退款</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="日志" :visible.sync="logVisible" center width="800px">
      <div>
        <div class="logInfoBox">
          <div class="logInfoItem">
            <div>代理商编号</div>
            <div>{{ logInfo.agentCode }}</div>
          </div>
          <div class="logInfoItem">
            <div>代理商编号</div>
            <div>{{ logInfo.agentName }}</div>
          </div>
          <div class="logInfoItem">
            <div>平台商编号</div>
            <div>{{ logInfo.platformOperatorCode }}</div>
          </div>
          <div class="logInfoItem">
            <div>平台商名称</div>
            <div>{{ logInfo.platformOperatorName }}</div>
          </div>
          <div class="logInfoItem">
            <div>业务类型</div>
            <div>{{ logInfo.businessTypeName }}</div>
          </div>
          <div class="logInfoItem">
            <div>组件片数</div>
            <div>{{ logInfo.moduleNum }}</div>
          </div>
          <div class="logInfoItem">
            <div>金融单号</div>
            <div>{{ logInfo.financeCode }}</div>
          </div>
          <div class="logInfoItem">
            <div>用户姓名</div>
            <div>{{ logInfo.userName }}</div>
          </div>
          <div class="logInfoItem">
            <div>代理商费用(元/板)</div>
            <div>{{ logInfo.unitePrice }}</div>
          </div>
          <div class="logInfoItem">
            <div>平台商费用(元/板)</div>
            <div>{{ logInfo.platformUnitePrice }}</div>
          </div>
          <div class="logInfoItem">
            <div>代理商费用总计</div>
            <div>{{ logInfo.totalPrice }}</div>
          </div>
          <div class="logInfoItem">
            <div>平台商费用总计</div>
            <div>{{ logInfo.olatformTotalOrice }}</div>
          </div>
        </div>
        <div class="logHistory">日志历史</div>
        <el-table
          ref="multipleTable"
          :data="logTableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
        >
          <el-table-column
            prop="operateName"
            label="操作人"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="disposeStatus"
            label="处理状态"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="disposeDate"
            label="处理时间"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expand: false,
      businessTypeOptions: [],
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {
          businessType: "",
          accountItemName:""
        }
      },
      tableData: [],
      total: 0,
      statusOptions: [
        {
          value: "0",
          label: "未支付"
        },
        {
          value: "1",
          label: "已支付"
        },
        {
          value: "2",
          label: "已退款"
        }
      ],
      logVisible: false,
      invoiceVisible: false,
      logInfo: {},
      logTableData: [],
      createDate: [], //生成时间
      initiateDate: [], //发起时间
      agentInsureDate: [], //代理商确认时间
      paymentApplyDate: [], //支付申请时间
      paymentFinishDate: [] //支付完成时间
    };
  },
  created() {
    this.getList();
    this.getBusinessTypeOptions();
  },
  methods: {
    createDateChange(time) {
      this.queryInfo.condition.createStartDate = time[0];
      this.queryInfo.condition.createEndDate = time[1];
    },
    initiateDateChange(time) {
      this.queryInfo.condition.initiateStartDate = time[0];
      this.queryInfo.condition.initiateEndDate = time[1];
    },
    toExpand() {
      this.expand = !this.expand;
    },
    getList() {
      if (this.createDate) {
        this.queryInfo.condition.createStartDate = this.createDate[0];
        this.queryInfo.condition.createEndDate = this.createDate[1];
      } else {
        this.queryInfo.condition.createStartDate = "";
        this.queryInfo.condition.createEndDate = "";
      }

      if (this.initiateDate) {
        this.queryInfo.condition.initiateStartDate = this.initiateDate[0];
        this.queryInfo.condition.initiateEndDate = this.initiateDate[1];
      } else {
        this.queryInfo.condition.initiateStartDate = "";
        this.queryInfo.condition.initiateEndDate = "";
      }

      if (this.agentInsureDate) {
        this.queryInfo.condition.agentInsureStartDate = this.agentInsureDate[0];
        this.queryInfo.condition.agentInsureEndDate = this.agentInsureDate[1];
      } else {
        this.queryInfo.condition.agentInsureStartDate = "";
        this.queryInfo.condition.agentInsureEndDate = "";
      }

      if (this.paymentApplyDate) {
        this.queryInfo.condition.paymentApplyStartDate = this.paymentApplyDate[0];
        this.queryInfo.condition.paymentApplyEndDate = this.paymentApplyDate[1];
      } else {
        this.queryInfo.condition.paymentApplyStartDate = "";
        this.queryInfo.condition.paymentApplyEndDate = "";
      }

      if (this.paymentFinishDate) {
        this.queryInfo.condition.paymentFinishStartDate = this.paymentFinishDate[0];
        this.queryInfo.condition.paymentFinishEndDate = this.paymentFinishDate[1];
      } else {
        this.queryInfo.condition.paymentFinishStartDate = "";
        this.queryInfo.condition.paymentFinishEndDate = "";
      }

      this.queryInfo.condition.agentCode = JSON.parse(localStorage.getItem("userInfo")).userInfo.agentCode;
      this.$http.post("/houseAgentBill/list", this.queryInfo).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.data.count;
          this.tableData = res.data.data.data;
        }
      });
    },
    getLogList() {
      this.$http
        .post("/houseAgentBill/queryLogList", {
          financeCode: this.logInfo.financeCode
        })
        .then(res => {
          if (res.data.code == 200) {
            this.logTableData = res.data.data.logList;
            this.logInfo = res.data.data.houseAgentBill;
          }
        });
    },
    getBusinessTypeOptions() {
      this.$http
        .post("/houseItem/queryList", {
          itemType: "2"
        })
        .then(res => {
          if (res.data.code == 200) {
            this.businessTypeOptions = res.data.data;
          }
        });
    },
    toShowLog(row) {
      this.logInfo = row;
      this.logVisible = true;
      this.getLogList();
    },
    toSearch() {
      this.getList();
      console.log(this.createDate);
    },
    toReset() {
      this.createDate = [];
      this.initiateDate = [];
      this.agentInsureDate = [];
      this.paymentApplyDate = [];
      this.paymentFinishDate = [];

      this.queryInfo.condition = {};
      this.getList();
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
.logInfoBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  .logInfoItem {
    display: flex;
    gap: 10px;
  }
  .logInfoItem > div:first-child {
    width: 120px;
    font-weight: bold;
  }
}
.logHistory {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}
/deep/.el-card__body {
  padding: 7px;
}
/deep/.el-form-item {
  margin-bottom: 7px;
}
/deep/ .el-table__body tr.hover-row > td {
  background-color: #f59a23 !important;
}
</style>
